.header {
  background-color: $black-50;
  padding: 24px 0;

  .profile-icon {
    width: 48px;
    height: 48px;

    &__container {
      border-radius: 50%;
      margin-left: auto;
      transition: background-color 0.2s linear;

      &:hover {
        background-color: $color-primary-200;
      }
    }
  }
}

.logo {
  display: inline-block;
  max-height: 48px;
  max-width: 186px;
  width: 100%;

  &--secondary {
    width: auto;
  }
}

.logo--secondary, .logo__container {
  @media (max-width: 499px) {
    max-width: 45%;
  }
}