.home {
  max-width: 318px;
  width: 100%;

  .logo {
    width: auto;

    @media (min-width: 500px) and (max-width: 639px) {
      max-width: 318px;
    }

    @media (max-width: 499px) {
      width: 100%;
      max-height: initial;
    }

    &__container {
      margin-bottom: 96px;
      text-align: center;

      @media (max-width: 499px) {
        max-width: initial;
      }
    }
  }


  .btn {
    text-align: center;

    @media (max-width: 575px) {
      width: 100%;
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;

      @media (min-width: 576px) {
        flex-direction: row;
      }
    }
  }
}