.form {
  margin-top: 40px;
  width: 100%;

  &__group {
    margin-bottom: 24px;

    &--checkboxes, &--radios {
      .form__group {
        &:last-child {
          margin-bottom: initial;
        }
      }
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 8px;
    text-transform: lowercase;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__input-container {
    input, textarea {
      border-radius: 8px;
      border: 1px solid $black-200;
      color: $black-900;
      padding: 14px 12px;
      width: 100%;

      &::placeholder {
        color: $black-300;
      }
    }

    &--password {
      position: relative;

      input {
        padding: 14px 62px 14px 12px;
      }
    }

    &.invalid {
      input, textarea {
        border-color: $error;
        background-color: $black-50;
      }
    }
  }

  &__select-container {
    select {
      border-radius: 8px;
      border: 1px solid $black-200;
      color: $black-300;
      padding: 14px 12px;
      width: 100%;
      appearance: none;

      &:not([multiple]) {
        background: white url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBpZD0iQ29udHJvbHMiPjxwYXRoIGlkPSJjaGV2cm9uLWRvd24iIGQ9Ik0xNi41IDkuNzVMMTIgMTQuMjVMNy41IDkuNzUiIHN0cm9rZT0iIzBGMTcyQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9nPjwvc3ZnPg==') no-repeat right 10px center;
      }

      &[multiple] {
        option[selected] {
          background-color: $color-primary-50;
        }
      }

      &.valid-selection {
        color: $black-900;
      }
    }

    &.invalid {
      select {
        border-color: $error;
        background-color: $black-50;
      }
    }
  }

  .password-btn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: initial;
    border: none;
    text-decoration: underline;

    &--hide {
      display: none;
    }
  }

  &__errors {
    margin: 8px 0 0;


    &--general {
      margin: 24px 12px 0;
    }

    .errorlist {
      li {
        color: $error-text;
        font-size: rem(12);
      }
    }
  }

  &__checkbox {
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;
    column-gap: 8px;
    position: relative;

    &-label {
      font-size: rem(14);
      line-height: rem(20);

      * {
        font-size: rem(14);
        line-height: rem(20);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      margin: 0;
      z-index: 1;
      top: 0;
      left: 0;
      pointer-events: none;

      &:focus-visible + .custom-checkbox {
        outline: 2px solid #6AD2FF;
        outline-offset: 2px;
      }
    }

    .custom-checkbox {
      background-color: $white;
      border-radius: 4px;
      border: 1px solid $black-600;
      display: inline-block;
      flex: none;
      height: 16px;
      margin-top: 2px;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      position: relative;
      pointer-events: all;
      width: 16px;
    }

    input:checked + .custom-checkbox {
      background-color: $black-600;
      border-color: $black-600;
    }

    input:checked + .custom-checkbox::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2717%27 viewBox=%270 0 16 17%27 fill=%27none%27%3E%3Cg id=%27Icon%27%3E%3Cpath id=%27check-alternative%27 d=%27M3.5 7.97059L6.83333 11.5L12.5 5.5%27 stroke=%27%23FFFFFF%27 stroke-width=%271.5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/g%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 17px;
    }

    &.invalid {
      .custom-checkbox, .form__checkbox-label {
        border-color: $error;
      }
    }
  }

  &__radio {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .custom-radio {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid black;
      background-color: transparent;
      border-radius: 50%;
      position: relative;
      margin-right: 8px;
      transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    input[type="radio"]:checked ~ .custom-radio {
      background-color: transparent;
      border-color: black;
    }

    input[type="radio"]:checked ~ .custom-radio::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: black;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    input[type="radio"]:focus ~ .custom-radio {
      outline-offset: 2px;
      outline: 4px solid $color-primary-300;
    }

    .form__radio-label {
      font-size: rem(14);
      line-height: rem(20);
    }

    &.invalid {
      .custom-radio, .form__radio-label {
        border-color: $error;
      }
    }
  }

  small {
    color: $black-600;
    display: block;
    font-size: rem(12);
    margin-top: 8px;
  }

  &--edit-profile {
    //.btn--primary {
    //  display: block;
    //  margin: 48px auto 0;
    //
    //  @media (max-width: 639px) {
    //    width: 100%;
    //  }
    //}
  }
}