.btn {
  border-radius: 8px;
  display: inline-block;
  padding: 16px 32px;
  text-align: center;

  &--primary {
    border: 1px solid $color-primary-500;
    background-color: $color-primary-500;
    color: $white;

    &:hover {
      background-color: $color-primary-700
    }
  }

  &--secondary {
    border: 1px solid $color-primary-500;
    background-color: $white;
    color: $color-primary-500;

    &:hover {
      background-color: $color-primary-50;
    }
  }

  &--back {
    display: inline-block;

    &:hover {
      img {
        scale: 1.3;
      }
    }

    img {
      width: 32px;
      height: 32px;
      transition: 0.1s all linear;
    }
  }
}