.footer {
  background-color: $black-50;

  &__content {
    &--logo {
      display: flex;
      flex-flow: row wrap;
      gap: 12px 52px;
      justify-content: center;
      align-items: center;
      padding: 16px 0;

      .logo--secondary {
        max-height: 30px;

        @media (max-width: 499px) {
          max-width: 116px;
        }
      }

      @media (max-width: 575px) {
        .footer__items {
          flex-flow: row wrap;
          gap: 16px;
        }

      }
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 0;

    @media (max-width: 575px) {
      flex-direction: column;
      row-gap: 8px;
    }
  }

  &__item {
    display: inline-flex;
  }

  &__link {
    text-decoration: underline;
    color: inherit;
  }

  &__pipe {
    margin-left: 6px;
    margin-right: 6px;

    @media (max-width: 575px) {
      display: none;
    }
  }
}