.case-introduction {
  p {
    font-size: rem(16);
    color: #1E293B;
    font-weight: 400;
  }
}

.case-prompt {
    font-size: rem(16);
    color: #1E293B;
    font-weight: 700;
    margin-bottom: rem(24);
}

.content-with-radius {
  border-radius: rem(32) rem(32) 0 0;
  background-color: #fff;
  padding: rem(24);
  margin-bottom: rem(24);
}