.leaderboard {
  &__item {
    padding: 0 24px 0 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 16px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:nth-child(-n+3) {
      background-color: $white;
      box-shadow: 0 32px 32px -8px rgb(0 0 0 / 8%);
      padding: 16px 24px 16px 16px;
      margin-bottom: 8px;

      &.leaderboard__item--current {
        background-color: $color-primary-50;
        border: 2px solid $color-primary-500;
      }

      .leaderboard__place, .leaderboard__points {
        background-color: $color-primary-100;
      }
    }

    &:nth-child(3) {
      margin-bottom: 16px;
    }

    &:first-child {
      box-shadow: 0 0 32px 8px rgba(0 0 0 / 8%);
    }

    &--current {
      background-color: $color-secondary-50;
      border: 2px solid $color-secondary-400;
      padding: 16px 24px 16px 16px;
    }
  }

  &__place, &__points {
    min-width: 32px;
    height: 32px;
    padding: 6px;
    border-radius: 8px;
    background-color: $color-secondary-100;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__name {
    font-size: rem(20);
    font-weight: $fw-700;
    line-height: 125%;
    word-break: break-word;
  }

  &__points {
    margin-left: auto;
  }
}
