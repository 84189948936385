.richtext-content {
  margin-bottom: 24px;

  * {
    color: $black-900;
  }

  p, a, li {
    font-size: rem(16);
    line-height: 125%;
  }

  h2, h3, h4 {
    font-weight: $fw-500;
    line-height: normal;
    margin: 16px 0;
  }

  h2 {
    font-size: rem(48);

    @media (max-width: 1599px) {
      font-size: rem(32);
    }

    @media (max-width: 1023px) {
      font-size: rem(30);
    }
  }

  h3 {
    font-size: rem(32);

    @media (max-width: 1599px) {
      font-size: rem(30);
    }

    @media (max-width: 1023px) {
      font-size: rem(28);
    }
  }

  h4 {
    font-size: rem(30);

    @media (max-width: 1599px) {
      font-size: rem(28);
    }

    @media (max-width: 1023px) {
      font-size: rem(24);
    }
  }

  .richtext-image {
    border-radius: 16px;
    display: block;
    margin-bottom: 16px;
    margin-top: 16px;
    object-fit: cover;
    width: 100%;

    &.left {
      margin-right: auto;
    }

    &.right {
      margin-left: auto;
    }

    &.left, &.right {
      @media (min-width: 1024px) {
        max-width: 50%;
      }
    }
  }

  iframe {
    aspect-ratio: 16 / 9;
    border-radius: 16px;
    height: auto;
    margin: 16px auto;
    width: 100%;
  }

  a {
    font-weight: $fw-500;
    text-decoration: underline;
    word-break: break-all;

    &:hover, &:focus-visible {
      color: $color-primary-500;
    }
  }

  p {
    padding-bottom: 16px;
  }

  ol, ul {
    margin-bottom: 16px;
  }

  ol {
    counter-reset: item;
    list-style-type: none;
    margin-top: 16px;
    padding-left: 40px;

    li {
      counter-increment: item;
      margin-bottom: 16px;
      position: relative;

      &::before {
        align-items: center;
        background-color: $black-900;
        border-radius: 50%;
        color: $white;
        content: counter(item);
        display: flex;
        height: 26px;
        justify-content: center;
        left: -40px;
        position: absolute;
        top: -3px;
        width: 26px;
      }

      ol {
        margin-top: 16px;

        li {
          &::before {
            background-color: $white;
            border: 1px solid $black-900;
            color: $black-900;
            content: counter(item, lower-alpha);
          }
        }
      }

    }
  }

  ul {
    list-style-type: none;
    padding-left: 16px;

    li {
      margin-bottom: 16px;
      position: relative;

      &::before {
        background-color: $black-900;
        border-radius: 50%;
        content: '';
        height: 8px;
        left: -16px;
        position: absolute;
        top: 6px;
        width: 8px;
      }

      ul {
        list-style: none;
        margin-top: 16px;

        li {
          &::before {
            background-color: $white;
            border: 1px solid $black-900;
          }
        }
      }
    }
  }
}
