.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-card {
    align-items: center;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0 32px 32px -8px rgb(0 0 0 / 8%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 24px 0;
    max-width: 800px;
    padding: 48px 24px;
    width: 100%;

    @media (max-width: 1599px) {
      max-width: 600px;
    }

    &__title {
      margin-right: 40px;
      margin-left: 40px;
    }

    .form {
      max-width: 600px;
      padding: 16px;

      @media (max-width: 1599px) {
        max-width: 552px;
      }

      @media (max-width: 767px) {
        padding: 0;
      }
    }

    .btn--back {
      align-self: flex-start;
      margin-bottom: 32px;
    }
  }

  &--onboarding {
    .onboarding-form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 800px;
      column-gap: 16px;
      margin: 0 auto;

      @media (max-width: 1599px) {
          max-width: 600px;

      }

      @media (max-width: 639px) {
        .btn {
          width: 50%;
        }
      }
    }

    .onboarding-card {
      text-align: center;

      &__title {
        margin-bottom: 28px;
        display: inline-block;
      }
    }

    .onboarding-indicators {
      margin-top: 40px;
      display: flex;

      .onboarding-indicator {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: $color-secondary-100;

        &:not(:last-child) {
          margin-right: 16px;
        }

        &.active {
          background-color: $color-secondary-500;
        }
      }
    }
  }

  &--contact-success {
    .richtext-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > *:first-child {
        margin-top: initial;
        padding-top: initial;
      }

      p {
        text-align: center;
      }

      li {
        align-items: center;
      }
    }

    .btn--back {
      margin-bottom: 16px;
    }
  }
}