body {
  * {
    font-family: Inter, sans-serif;
    font-size: rem(16);
    line-height: rem(18);
    color: $black-600;
  }

  h1, h1 span {
    font-size: rem(56);
    line-height: normal;

    @media (max-width: 1599px) {
      font-size: rem(48);
    }

    @media (max-width: 1023px) {
      font-size: rem(32);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--custom-font, 'Helvetica Neue', sans-serif);
    font-weight: 500;

    * {
      font-family: var(--custom-font, 'Helvetica Neue', sans-serif);
    }
  }

  h2 {
    color: $black-900;
    font-size: rem(32);
    font-weight: 700;
    line-height: rem(39);
  }

  h3 {
    color: $black-800;
    font-size: rem(20);
    font-weight: 700;
    line-height: normal;
  }

  h4 {
    font-size: rem(24);
    font-weight: 500;
    line-height: rem(29);
  }

  h5 {
    font-size: rem(20);
    font-weight: 700;
    line-height: rem(24);
  }

  p {
    font-size: rem(16);
    font-weight: 400;
    line-height: rem(20);
  }
}

.text-link {
  font-weight: 600;
  text-decoration: underline;
  line-height: rem(24);
}

$fw-500: 500;
$fw-700: 700;