//@import "../utils/colors";

html {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;

 /* Remove focus outline on mouse click */
  input:focus {
    outline: none;
  }

  /* Apply focus-visible only for keyboard navigation */
  input:focus-visible {
    outline: 2px solid #6AD2FF;
    outline-offset: 2px;
  }

  input[type="radio"] {
    border-radius: 50%;
    accent-color: $black-900;
  }

  /* Ensure other elements also behave correctly with keyboard navigation */
  *:focus-visible {
    outline: 2px solid #6AD2FF;
    outline-offset: 2px;
  }

  input[type="checkbox"] {
    accent-color: $black-900;
  }


  input:not([type="radio"], [type="checkbox"]), select, textarea {
    &:focus-visible {
      border: 1px solid #6AD2FF;
      outline: 4px solid #E1F6FF;
      outline-offset: initial;
    }
  }

  /* For any element that receives focus, but not through keyboard navigation (mouse clicks) */
  *:focus:not(:focus-visible) {
    outline: none;
  }
}

header {
  flex: 0 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  position: relative;

  // background: var(--background-gradient);

  &.custom {
    display: block;
    background: initial;

    .background-svg {
      background-color: initial !important;
    }

    h1 {
      margin-top: 48px;
      margin-bottom: 40px;
    }
  }

  .background-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%271728%27 height=%27525%27 viewBox=%270 0 1728 525%27 fill=%27none%27%3E%3Cpath d=%27M1728 72.1538C1728 72.1538 1420.06 284.719 748.347 90.3699C347.044 -25.7394 290.254 -32.9463 1.79957 89.8735L0 524.5H1728V72.1538Z%27 fill=%27white%27 fill-opacity=%270.1%27/%3E%3Cpath d=%27M811.688 286.39C256.863 468.798 0 247.711 0 247.711V524.5H1727.61L1727.61 357.884C1728.04 363.16 1728.21 361.231 1727.61 346.281C1727.36 346.202 1727.12 346.123 1726.87 346.044C1392.09 238.784 1147.19 176.089 811.688 286.39Z%27 fill=%27white%27 fill-opacity=%270.1%27/%3E%3C/svg%3E');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    z-index: -1;
  }

  &--dashboard {
    justify-content: flex-start;

    .background-svg {
      transform: scaleY(-1);
    }
  }
}

footer {
  flex: 0 0;
}

.shadow {
  box-shadow: 0 32px 32px -8px rgb(0 0 0 / 8%);
}

.alert {
  background-color: #FFDCE0;
  color: #B92335;
  padding: 24px;
  border-radius: 12px;
}

.sticky {
  position: sticky;
  bottom: 0;
}