.resources {
  background-color: $white;
  border-radius: 16px;

  &__wrapper {
    display: block;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    padding: 8px;
    border-radius: 8px;

    @media (min-width: 640px) {
      &:hover {
        border: 1px solid $color-secondary-400;
        padding: 7px;
      }
    }

    @media (max-width: 639px) {
      padding: 8px 0;
    }

  }

  &__img {
    width: 32px;
    height: 32px;

    &-container {
      border-radius: 8px;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      background-color: $color-secondary-50;
    }
  }

  &__title {
    font-size: rem(20);
    line-height: 125%;
    font-weight: $fw-700;
    color: $black-800;
    margin-bottom: 4px;
  }

    &__description {
    font-size: rem(14);
    line-height: 125%;
    color: $black-600;
  }
}