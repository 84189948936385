:root {
  --color-primary-default: #f83c6b;
  --color-primary-50: #fff1f3;
  --color-primary-100: #ffe3e7;
  --color-primary-200: #ffccd6;
  --color-primary-300: #ffa2b5;
  --color-primary-400: #fe6e8e;
  --color-primary-500: #f83c6b;
  --color-primary-600: #e51956;
  --color-primary-700: #c20e48;
  --color-primary-800: #a20f44;
  --color-primary-900: #8b1040;
  --color-primary-950: #4e031e;
  --color-secondary-default: #eb9934;
  --color-secondary-50: #fef7ec;
  --color-secondary-100: #fae9cb;
  --color-secondary-200: #f5d092;
  --color-secondary-300: #efb35a;
  --color-secondary-400: #eb9934;
  --color-secondary-500: #e4781c;
  --color-secondary-600: #ca5815;
  --color-secondary-700: #a83c15;
  --color-secondary-800: #882f18;
  --color-secondary-900: #702817;
  --color-secondary-950: #401208;
  --color-background-default: #21a896;
  --color-background-50: #f1fcf9;
  --color-background-100: #d0f7ee;
  --color-background-200: #a1eedd;
  --color-background-300: #6adec9;
  --color-background-400: #3bc6b2;
  --color-background-500: #21a896;
  --color-background-600: #18897c;
  --color-background-700: #176e65;
  --color-background-800: #175851;
  --color-background-900: #184945;
  --color-background-950: #082b2a;
  --background-gradient: linear-gradient(224deg, var(--color-primary-200) 0%, #FFF 52%, var(--color-secondary-200) 100%);
}

$white: #fff;
$black-900: #0F172A;
$black-800: #1E293B;
$black-600: #475569;
$black-400: #94A3B8;
$black-300: #CBD5E1;
$black-200: #E2E8F0;
$black-100: #F1F5F9;
$black-50: #F8FAFC;
$error: #FF465D;
$error-text: #B92335;
$color-primary-50: var(--color-primary-50);
$color-primary-100: var(--color-primary-100);
$color-primary-200: var(--color-primary-200);
$color-primary-300: var(--color-primary-300);
$color-primary-400: var(--color-primary-400);
$color-primary-500: var(--color-primary-500);
$color-primary-600: var(--color-primary-600);
$color-primary-700: var(--color-primary-700);
$color-secondary-50: var(--color-secondary-50);
$color-secondary-100: var(--color-secondary-100);
$color-secondary-200: var(--color-secondary-200);
$color-secondary-400: var(--color-secondary-400);
$color-secondary-500: var(--color-secondary-500);
$color-secondary-600: var(--color-secondary-600);
$color-background-500: var(--color-background-500);
$color-background-600: var(--color-background-600);

.bg-success {
  background-color: #D7F8E0;
}

.text-success {
  color: #0A5B0E;
}

.bg-error {
  background-color: #FFDCE0;
}

.text-error {
  color: #B92335;
}

.error {
  color: #B92335;
  margin-top: 4px;
}

.bg-warning {
  background-color: #FEFFE0;
}

.text-warning {
  color: #65570F;
}

.bg-info {
  background-color: #E1F6FF;
}

.text-info {
  color: #275F8D;
}

.bg-initial {
  background: initial;
}
