.dashboard {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 32px 0 0;
  position: relative;
  width: 100%;

  h1, h1 span {
    color: $white;
    margin-bottom: 36px;
  }

  &__icon {
    width: 96px;
    height: 96px;
    display: inline-block;

    &-container {
       text-align: center;
       margin: 0 auto 24px;
    }
  }

  &__score {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: inherit;
    position: absolute;
    right: 24px;
    row-gap: 4px;
    top: 0;

    @media (max-width: 639px) {
      right: 0;
    }

    &-number {
      align-items: center;
      background-color: $white;
      border-radius: 8px;
      color: $color-primary-500;
      display: flex;
      font-weight: $fw-700;
      height: 44px;
      justify-content: center;
      padding: 6px;
      width: 44px;
    }

    &-label {
      font-size: rem(12);
      line-height: normal;
      color: $white;
    }
  }

  &__content {
    background-color: $white;
    border-radius: 32px 32px 0 0;
    flex: 1;
    padding: 32px 0;
  }

  &__tooltip {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 32px 16px;
    background-color: $white;
    border-radius: 12px;
    border: 2px solid $color-secondary-400;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
    font-weight: 600;
    transition: all 0.2s linear;
  }

  .navbar {
    margin-top: 24px;

    &__list {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      gap: 24px;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      @media (max-width: 639px) {
        gap: 16px;
      }
    }

    &__item {
      width: calc(50% - 12px);

      @media (max-width: 639px) {
        width: calc(50% - 8px);
      }
    }

    &__link {
      align-items: center;
      background-color: $white;
      border-radius: 8px;
      border: 1px solid $black-100;
      box-shadow: 0 8px 24px -6px rgba(0 0 0 / 8%);
      gap: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px 16px;
      text-decoration: none;
      transition: background-color 0.2s linear;
      width: 100%;

      @media (max-width: 639px) {
        padding: 16px;
      }

      &:hover {
        background-color: $color-primary-50;
      }

      &--highlighted {
        background-color: $color-primary-500;

        &:hover {
          background-color: $color-primary-700;
        }

        .navbar__icon-path {
          fill: $color-primary-500;
        }

        .navbar__text {
          color: $white;
          font-size: rem(24);
          line-height: 125%;
        }
      }

      &--disabled {
        cursor: initial;
        position: relative;
        pointer-events: none;

        .navbar__text {
          color: $black-200;
        }

        .navbar__icon {
          &-path {
            fill: $black-400;
          }

          &--lock {
            display: inline-block;
          }

          &-container {
            background-color: $black-200;
          }
        }

        &:hover {
          background-color: $white;

          .dashboard__tooltip {
            opacity: 1;
          }
        }
      }
    }

    &__icon {
      height: 36px;
      width: 36px;

      &-path {
        fill: $color-secondary-500;
      }


      &-container {
        background-color: $color-secondary-50;
        border-radius: 8px;
        padding: 6px;
      }

      &--lock {
        display: none;
        height: 24px;
        width: 24px;
      }
    }

    &__text {
      color: $black-600;
      font-size: rem(24);
      line-height: 125%;
      font-weight: 700;
      text-align: center;

      @media (max-width: 639px) {
        font-size: rem(20);
      }
    }
  }

}